import { Injectable } from "@angular/core";
import { ConfigurationService } from "../api-client";
import { ConfigurationResponseModel } from "../api-client/model/configurationResponseModel";

@Injectable()
export class ConfigurationProvider{
    
    private configurations : ConfigurationResponseModel[] = [];
    constructor(private configService : ConfigurationService){}

    public init() : void{
        let that = this;
        setTimeout(function() {
            that.loadConfigurations();
        }, 1000);
    }

    private async loadConfigurations() : Promise<void> {
        let result = await this.configService.configurationGetCommunicationConfiguration().toPromise();
        if(result != undefined){
            this.configurations = result;
        }
        console.log(this.configurations);
    } 

    private async getConfiguration() : Promise<ConfigurationResponseModel[]> {
        if(!this.configurations || this.configurations.length == 0){
            await this.loadConfigurations();
        }
        return this.configurations;
    }

    public async getConfigurationByKey(searchKey : string){
        let config = await this.getConfiguration();
        return config.find(x => x.key == searchKey)?.value;
    }

} 