/**
 * RenalIQ Communication API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface BaseViewModel { 
    id?: string;
    applicationId?: string;
    applicationName?: string;
    isDeleted: boolean;
    createdBy: number;
    createdByName?: string;
    createdOn: Date;
    updatedBy?: number;
    updatedByName?: string;
    updatedOn?: Date;
}