/**
 * RenalIQ Communication API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { IChatAuthToken } from '../model/iChatAuthToken';
import { IChatMessageRequest } from '../model/iChatMessageRequest';
import { IChatStart } from '../model/iChatStart';
import { IChatThread } from '../model/iChatThread';
import { IChatThreadResponse } from '../model/iChatThreadResponse';
import { IChatUser } from '../model/iChatUser';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ChatService {

    protected basePath = 'http://localhost:5000/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param applicationId 
     * @param sessionUserId 
     * @param threadKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chatAddThreadParticipant(body: IChatUser, applicationId: string, sessionUserId: string, threadKey: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public chatAddThreadParticipant(body: IChatUser, applicationId: string, sessionUserId: string, threadKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public chatAddThreadParticipant(body: IChatUser, applicationId: string, sessionUserId: string, threadKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public chatAddThreadParticipant(body: IChatUser, applicationId: string, sessionUserId: string, threadKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling chatAddThreadParticipant.');
        }

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling chatAddThreadParticipant.');
        }

        if (sessionUserId === null || sessionUserId === undefined) {
            throw new Error('Required parameter sessionUserId was null or undefined when calling chatAddThreadParticipant.');
        }

        if (threadKey === null || threadKey === undefined) {
            throw new Error('Required parameter threadKey was null or undefined when calling chatAddThreadParticipant.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/api/Chat/${encodeURIComponent(String(applicationId))}/${encodeURIComponent(String(sessionUserId))}/threads/${encodeURIComponent(String(threadKey))}/users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param threadKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chatChatThreadDetail(applicationId: string, threadKey: string, observe?: 'body', reportProgress?: boolean): Observable<IChatThread>;
    public chatChatThreadDetail(applicationId: string, threadKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IChatThread>>;
    public chatChatThreadDetail(applicationId: string, threadKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IChatThread>>;
    public chatChatThreadDetail(applicationId: string, threadKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling chatChatThreadDetail.');
        }

        if (threadKey === null || threadKey === undefined) {
            throw new Error('Required parameter threadKey was null or undefined when calling chatChatThreadDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IChatThread>('get',`${this.basePath}/api/Chat/${encodeURIComponent(String(applicationId))}/threads/${encodeURIComponent(String(threadKey))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param sessionUserId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chatChatThreadsByUser(applicationId: string, sessionUserId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<IChatThread>>;
    public chatChatThreadsByUser(applicationId: string, sessionUserId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IChatThread>>>;
    public chatChatThreadsByUser(applicationId: string, sessionUserId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IChatThread>>>;
    public chatChatThreadsByUser(applicationId: string, sessionUserId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling chatChatThreadsByUser.');
        }

        if (sessionUserId === null || sessionUserId === undefined) {
            throw new Error('Required parameter sessionUserId was null or undefined when calling chatChatThreadsByUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<IChatThread>>('get',`${this.basePath}/api/Chat/${encodeURIComponent(String(applicationId))}/${encodeURIComponent(String(sessionUserId))}/threads`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param sessionUserId 
     * @param threadKey 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chatDeleteThreadParticipant(applicationId: string, sessionUserId: string, threadKey: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public chatDeleteThreadParticipant(applicationId: string, sessionUserId: string, threadKey: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public chatDeleteThreadParticipant(applicationId: string, sessionUserId: string, threadKey: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public chatDeleteThreadParticipant(applicationId: string, sessionUserId: string, threadKey: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling chatDeleteThreadParticipant.');
        }

        if (sessionUserId === null || sessionUserId === undefined) {
            throw new Error('Required parameter sessionUserId was null or undefined when calling chatDeleteThreadParticipant.');
        }

        if (threadKey === null || threadKey === undefined) {
            throw new Error('Required parameter threadKey was null or undefined when calling chatDeleteThreadParticipant.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling chatDeleteThreadParticipant.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Chat/${encodeURIComponent(String(applicationId))}/${encodeURIComponent(String(sessionUserId))}/threads/${encodeURIComponent(String(threadKey))}/users/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param applicationId 
     * @param threadKey 
     * @param maxResult 
     * @param beforeMessageId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chatGetMessageHistory(applicationId: string, threadKey: string, maxResult?: number, beforeMessageId?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public chatGetMessageHistory(applicationId: string, threadKey: string, maxResult?: number, beforeMessageId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public chatGetMessageHistory(applicationId: string, threadKey: string, maxResult?: number, beforeMessageId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public chatGetMessageHistory(applicationId: string, threadKey: string, maxResult?: number, beforeMessageId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling chatGetMessageHistory.');
        }

        if (threadKey === null || threadKey === undefined) {
            throw new Error('Required parameter threadKey was null or undefined when calling chatGetMessageHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (maxResult !== undefined && maxResult !== null) {
            queryParameters = queryParameters.set('maxResult', <any>maxResult);
        }
        if (beforeMessageId !== undefined && beforeMessageId !== null) {
            queryParameters = queryParameters.set('beforeMessageId', <any>beforeMessageId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Chat/${encodeURIComponent(String(applicationId))}/threads/${encodeURIComponent(String(threadKey))}/messages`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chatGetToken(body: IChatUser, applicationId: string, observe?: 'body', reportProgress?: boolean): Observable<IChatAuthToken>;
    public chatGetToken(body: IChatUser, applicationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IChatAuthToken>>;
    public chatGetToken(body: IChatUser, applicationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IChatAuthToken>>;
    public chatGetToken(body: IChatUser, applicationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling chatGetToken.');
        }

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling chatGetToken.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<IChatAuthToken>('post',`${this.basePath}/api/Chat/${encodeURIComponent(String(applicationId))}/token`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param applicationId 
     * @param threadKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chatSendMessage(body: IChatMessageRequest, applicationId: string, threadKey: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public chatSendMessage(body: IChatMessageRequest, applicationId: string, threadKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public chatSendMessage(body: IChatMessageRequest, applicationId: string, threadKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public chatSendMessage(body: IChatMessageRequest, applicationId: string, threadKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling chatSendMessage.');
        }

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling chatSendMessage.');
        }

        if (threadKey === null || threadKey === undefined) {
            throw new Error('Required parameter threadKey was null or undefined when calling chatSendMessage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/api/Chat/${encodeURIComponent(String(applicationId))}/threads/${encodeURIComponent(String(threadKey))}/messages`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param applicationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chatStartChat(body: IChatStart, applicationId: string, observe?: 'body', reportProgress?: boolean): Observable<IChatThreadResponse>;
    public chatStartChat(body: IChatStart, applicationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IChatThreadResponse>>;
    public chatStartChat(body: IChatStart, applicationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IChatThreadResponse>>;
    public chatStartChat(body: IChatStart, applicationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling chatStartChat.');
        }

        if (applicationId === null || applicationId === undefined) {
            throw new Error('Required parameter applicationId was null or undefined when calling chatStartChat.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<IChatThreadResponse>('post',`${this.basePath}/api/Chat/${encodeURIComponent(String(applicationId))}/threads`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
