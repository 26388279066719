import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
// import { ApiModule, BASE_PATH } from './api-client';

import { AppRoutingDeclarations, AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { VideoChatModule } from './video-chat/video-chat.module';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { RequestHeadersInterceptor } from './auth/request-headers-interceptor';
import { ApiModule, BASE_PATH } from './api-client';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { UnsubscribeSuccessComponent } from './unsubscribe-success/unsubscribe-success.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { MeetingInfoComponent } from './video-player/meeting-info/meeting-info.component';
import { DatePipe } from '@angular/common';
import { CommonDateTimeFormatter } from './common/pipes/common-date-time-formatter';
import { USDateTimePipe } from './common/pipes/us-datetime.pipe';
import { ComsettingComponent } from './comsetting/comsetting.component';
import { SettingModule } from './setting/setting.module';
import { ToastrModule } from 'ngx-toastr';
import { ResponseInterceptor } from './auth/response-interceptor';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';





const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);   // For log on console 
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.authClientID,
      authority: environment.authAuthority,
      redirectUri: environment.authRedirectURI,
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.graph_endpoint, ['user.read']);
  protectedResourceMap.set(environment.baseApiUrl, [environment.apiClientID]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}
export function appInsightsFactory() {
  return new ApplicationInsights({
    config: {
      instrumentationKey: environment.connectionString,
    }
  });
}

@NgModule({
  declarations: [
    AppComponent,
    AppRoutingDeclarations,
    UnsubscribeComponent,
    UnsubscribeSuccessComponent,
    VideoPlayerComponent,
    MeetingInfoComponent,
    USDateTimePipe,
    ComsettingComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ApiModule,
    CoreModule,
    MsalModule,
    VideoChatModule,
    SettingModule,
    ToastrModule.forRoot({}),

  ],
  providers: [
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: MsalInterceptor,
    //  multi: true
    //},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHeadersInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    //VideoCallService,
    { provide: BASE_PATH, useValue: environment.baseApiUrl },
    DatePipe,
    { provide: CommonDateTimeFormatter, useFactory: (dataTimePipe: DatePipe) => new CommonDateTimeFormatter(dataTimePipe), deps: [DatePipe] },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
  }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
