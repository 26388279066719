import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoCallFeedbackRequest } from 'src/app/api-client/model/videoCallFeedbackRequest';
import { MeetingStatisticsService } from '../../api-client/api/meetingStatistics.service';
import { VideoCallFeedbackService } from '../../api-client/api/videoCallFeedback.service';
import { MeetingStatsRequest } from '../../api-client/model/meetingStatsRequest';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  patientDetials = {
    patientId: 123,
    patientName: 'First Patient',
    dateOfBirth: '26/05/2000',
    primaryCareMember: "Care Member",
    appointmentType: "Home"
  }
  meetingId: string = '';
  feedbackForm: FormGroup;
  ratings: string = '';
  constructor(private fb: FormBuilder, 
    private videoCallFeedbackService: VideoCallFeedbackService, 
    private route: ActivatedRoute,
    private router: Router,
    private meetingStatisticsService: MeetingStatisticsService  ) {
    this.feedbackForm = this.fb.group({
      meetingStatus: ['', Validators.required],
      meetingfeedback: ['', Validators.required],
      device: ['ios', Validators.required],
      rating: [null, Validators.required]
    })
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['meetingId']) {
        this.meetingId = params['meetingId'];
      } else {
        this.router.navigate(['invalid-meeting']);
      }
    });
  }
  selectedRating(value: string) {
    this.ratings = value;
    this.feedbackForm.controls['rating'].setValue(value);
  }
  onFeedbackSubmit() {
    const data: VideoCallFeedbackRequest = {
        ...this.feedbackForm.value,
      meetingId: this.meetingId,
      rating: this.ratings,
    }
    this.videoCallFeedbackService.videoCallFeedbackPost(data).subscribe((response) => {
      let request: MeetingStatsRequest = {
        privateMeetingId: this.meetingId,
        status: 'Feedback',
        type: 'Care Member',
        description: 'Care Member shared feedback'
      }
      this.meetingStatisticsService.meetingStatisticsSaveMeetingStats(request).subscribe((res) => {
        window.open('https://somatus.com/', "_self");
      });
    });
  }

}
