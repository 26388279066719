import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Route, Router } from "@angular/router";
import { MsalGuardConfiguration, MsalInterceptorConfiguration, MsalService, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { AuthenticationResult, InteractionType } from "@azure/msal-browser";

import { catchError, EMPTY, Observable, switchMap } from "rxjs";
import { environment } from "../../environments/environment";



@Injectable()
export class RequestHeadersInterceptor implements HttpInterceptor {


  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfiguration,
    private authService: MsalService, private _router: Router) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this._router.url.includes("/telehealth") || this._router.url.includes("/consent")
      || this._router.url.includes("/joining-link")) {
      return next.handle(req);
    }
    //'api://bcc623ab-d9eb-4141-a344-841f1ed1ba5e/app-hyrbid-scheduler-api'
    const scopes = [environment.apiClientID];
    const accounts = this.authService.instance.getAllAccounts();
    const account = accounts[0];
    return this.authService.acquireTokenSilent({ scopes, account })
      .pipe(
        catchError(() => {
          if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            return this.authService.acquireTokenPopup({ ...this.msalGuardConfig.authRequest, scopes });
          }
          const redirectStartPage = window.location.href;
          this.authService.acquireTokenRedirect({ ...this.msalGuardConfig.authRequest, scopes, redirectStartPage });
          return EMPTY;
        }),
        switchMap((result: AuthenticationResult) => {
          const headers = req.headers
            .set('Authorization', `Bearer ${result.accessToken}`);

          const requestClone = req.clone({ headers });
          return next.handle(requestClone);
        })
      );
  }

}
