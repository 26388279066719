import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '../api-client/api/communication.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  constructor(private communicationService: CommunicationService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
  }
  unsubscribe(){
    this.communicationService.communicationUpdateEmailSubscriptionStatus(this.route.snapshot.params['token']).subscribe((response) => {
      console.log(response);
      this.router.navigate(['success']);
    })
  }
  notUnsubscribe() {
    window.open('https://somatus.com', '_self');
  }
}
