import { Component, OnInit } from '@angular/core';
import { ServiceMasterService } from '../../../api-client';
import { CommonConstants } from '../../../shared/common-constants';

@Component({
  selector: 'app-email-setting',
  templateUrl: './email-setting.component.html',
  styleUrls: ['./email-setting.component.scss']
})
export class EmailSettingComponent implements OnInit {

  serviceMasterId: any; // = CommonConstants.EMAIL_SERVICEMASTERID;

  constructor(private _serviceMasterService: ServiceMasterService,) { }

  ngOnInit(): void {
    this.getAll();
  }
  public async getAll() {
    let services: any = await this._serviceMasterService.serviceMasterGetAll().toPromise();
    let obj = services.find((x: any) => x.type == 'Email');
    this.serviceMasterId = obj.id;
  }


}
