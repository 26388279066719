import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unsubscribe-success',
  templateUrl: './unsubscribe-success.component.html',
  styleUrls: ['./unsubscribe-success.component.scss']
})
export class UnsubscribeSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  goToSomatusHome() {
    window.open('https://somatus.com', '_self');
  }
}
