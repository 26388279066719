import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginFailedComponent } from './auth/login-failed/login-failed.component';
import { LoginComponent } from './auth/login/login.component';
import { LayoutComponent } from './core';
import { ErrorComponent } from './core/components/error/error.component';
import { UnsubscribeSuccessComponent } from './unsubscribe-success/unsubscribe-success.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { ConsentComponent } from './video-chat/consent/consent.component';
import { FeedbackComponent } from './video-chat/feedback/feedback.component';
import { JoiningLinkComponent } from './video-chat/joining-link/joining-link.component';
import { TelehealthComponent } from './video-chat/telehealth/telehealth.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { ComsettingComponent } from './comsetting/comsetting.component';
import { UnauthorizedComponent } from './auth/unauthorized/unauthorized.component';
import { CacheManagementComponent } from './cache-management/components/cache-management/cache-management.component';


export const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login-failed', component: LoginFailedComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },

  { path: 'consent', component: ConsentComponent },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'telehealth', component: TelehealthComponent },
  { path: 'joining-link', component: JoiningLinkComponent },
  { path: 'unsubscribe/:token', component: UnsubscribeComponent },
  { path: 'invalid-meeting', component: ErrorComponent },
  { path: 'success', component: UnsubscribeSuccessComponent },
  { path: 'player/:meetingId', component: VideoPlayerComponent },
  { path: 'comsetting', component: ComsettingComponent },
  

  //{
  //  path: '', component: LayoutComponent, children: [
  //    { path: 'settings', component: SettingComponent },
  //    { path: 'settings/sms', component: SmsSettingComponent },
  //    { path: 'telehealth', component: TelehealthSettingComponent },
  //    { path: 'settings/sms', component: EmailSettingComponent }

  //  ],
  //},

  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'chats',
        loadChildren: () =>
          import('./chats/chats.module').then((m) => m.ChatsModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./setting/setting.module').then((m) => m.SettingModule),
      },
      {
        path: 'integration',
        loadChildren: () =>
          import('./integration/integration.module').then(
            (m) => m.IntegrationModule
          ),
      },
      { path: 'cache-management',
        loadChildren: () =>
          import('./cache-management/cache-management.module').then(
            (m) => m.CacheManagementModule
          ),
      },
    ],
  },
  // {
  //   path: 'chats',
  //   loadChildren: () => import('./chats/chats.module').then(m => m.ChatsModule)
  // },
  {
    path: 'contacts',
    loadChildren: () =>
      import('./contacts/contacts.module').then((m) => m.ContactsModule),
  },
  /* { path: '**', redirectTo: 'invalid-meeting' }*/

  //{ path: '', redirectTo: 'auth', pathMatch: 'prefix' },
  //{
  //  path: 'auth',
  //  loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  //},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export const AppRoutingDeclarations = [];
