import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoCallService } from '../../api-client';

@Component({
  selector: 'app-telehealth',
  templateUrl: './telehealth.component.html',
  styleUrls: ['./telehealth.component.css']
})
export class TelehealthComponent implements OnInit {

  publicMeetingId: any = null;
  scheduleInfo: any = {};
  testingMode: boolean = false;

  constructor(private router: Router,
    private _activeRoute: ActivatedRoute,
    private _videocallService: VideoCallService
  ) { }

  ngOnInit(): void {
    this.getQueryStringFromUrl();
  }

  getQueryStringFromUrl() {
    this._activeRoute.queryParams.subscribe((params: any) => {
      if (!params.u) this.router.navigate(['invalid-meeting']);
      this.publicMeetingId = params.u;
      this.testingMode = params.testing;

      if (this.testingMode) {
        this.getTestingScheduleInfo(this.publicMeetingId);
      }
      else {
        this.getScheduleInfo(this.publicMeetingId);
      }
    });
  }

  async getScheduleInfo(publicMeetingId: string) {
    let that = this;
    that.scheduleInfo = await this._videocallService.videoCallScheduleInfo(publicMeetingId).toPromise();

    if (that.scheduleInfo) {
      that.router.navigate(['/consent'], {
        queryParams: {
          u: `${that.scheduleInfo.privateMeetingId}`,
          paitentName: `${that.scheduleInfo.data['patientName']}`,
          serviceType: `${that.scheduleInfo.serviceType}`,
          host: `${that.scheduleInfo.onlineMeetingHost}`,
          meetingRoomId: `${that.scheduleInfo.id}`
        }
      });
    }
    else {
      console.log("meeting not found.")
    }
  }

  async getTestingScheduleInfo(publicMeetingId: string) {
    let that = this;
    that.scheduleInfo = await this._videocallService.videoCallTestScheduleInfo(publicMeetingId).toPromise();

    if (that.scheduleInfo) {
      that.router.navigate(['/consent'], {
        queryParams: {
          u: `${that.scheduleInfo.privateMeetingId}`,
          paitentName: `${that.scheduleInfo.data['patientName']}`,
          serviceType: `${that.scheduleInfo.serviceType}`,
          host: `${that.scheduleInfo.onlineMeetingHost}`,
          meetingRoomId: `${that.scheduleInfo.id}`
        }
      });
    }
    else {
      console.log("meeting not found.")
    }
  }
}
