<div class="main__nav">
  <div class="main__nav-block">
    <a class="main__logo" [routerLink]="['/']">
      <svg
        viewBox="0 0 32 32"
        class="svg svg-primary svg-2-5"
        aria-hidden="true"
      >
        <use class="svg" xlink:href="#svg-logo"></use>
      </svg>
    </a>
    <span
      class="main__nav-link d-xl-none"
      data-toggle="nav-tooltip"
      data-placement="right"
      title="Menu"
      (click)="menuToggle()"
    >
      <svg
        viewBox="0 0 448 512"
        class="svg svg-secondary svg-1-125 is-menu__hide"
        aria-hidden="true"
      >
        <use class="svg" xlink:href="#svg-menu"></use>
      </svg>
      <svg
        viewBox="0 0 320 512"
        class="svg svg-secondary svg-1-125 is-menu__show"
        aria-hidden="true"
      >
        <use class="svg" xlink:href="#svg-close"></use>
      </svg>
    </span>
    <a
      class="main__nav-link"
      [routerLink]="['/']"
      routerLinkActive=""
      data-placement="right"
    >
      <svg
        viewBox="1 1 22 22"
        class="svg svg-secondary svg-1-125"
        aria-hidden="true"
      >
        <use class="svg" xlink:href="#svg-home"></use>
      </svg>
    </a>
    <a
      *ngIf="isVisibleChat"
      class="main__nav-link"
      [routerLink]="'/chats'"
      routerLinkActive="is-active"
      data-toggle="nav-tooltip"
      data-placement="right"
      title="Chats"
    >
      <svg
        viewBox="0 0 640 512"
        class="svg svg-secondary svg-1-125"
        aria-hidden="true"
      >
        <use class="svg" xlink:href="#svg-chat"></use>
      </svg>
    </a>
    <a
      *ngIf="isVisibleContact"
      class="main__nav-link"
      [routerLink]="'/contacts'"
      routerLinkActive="is-active"
      data-toggle="nav-tooltip"
      data-placement="right"
      title="Contacts"
    >
      <svg
        viewBox="2 2 28 28"
        class="svg svg-secondary svg-1-125"
        aria-hidden="true"
      >
        <use class="svg" xlink:href="#svg-contact"></use>
      </svg>
    </a>
    <a
      *ngIf="isVisibleSetting"
      class="main__nav-link"
      [routerLink]="'/settings'"
      routerLinkActive="is-active"
      data-toggle="nav-tooltip"
      data-placement="right"
      title="Settings"
    >
      <svg
        viewBox="0 0 512 512"
        class="svg svg-secondary svg-1-125"
        aria-hidden="true"
      >
        <use class="svg" xlink:href="#svg-settings"></use>
      </svg>
    </a>
    <a
      class="main__nav-link"
      [routerLink]="'/integration/scheduler'"
      routerLinkActive="is-active"
      data-toggle="nav-tooltip"
      data-placement="right"
      title="Template Management"
    >
      <svg
        viewBox="0 0 24 24"
        class="svg svg-secondary svg-1-125"
        aria-hidden="true"
      >
        <use class="svg" xlink:href="#svg-template"></use>
      </svg>
    </a>
    <a
      *ngIf="isVisibleSetting"
      class="main__nav-link"
      [routerLink]="'/cache-management'"
      routerLinkActive="is-active"
      data-toggle="nav-tooltip"
      data-placement="right"
      title="Cache Management"
    >
      <svg
        viewBox="0 0 24 24"
        class="svg svg-secondary svg-1-125"
        aria-hidden="true"
      >
        <use class="svg" xlink:href="#svg-cache"></use>
      </svg>
    </a>
  </div>
  <a
    href="javascript(void);"
    (click)="logout()"
    class="main__nav-link"
    data-toggle="nav-tooltip"
    data-placement="right"
    title="Logout"
  >
    <svg
      viewBox="0 0 512 512"
      class="svg svg-secondary svg-1-125"
      aria-hidden="true"
    >
      <use class="svg" xlink:href="#svg-logout"></use>
    </svg>
  </a>
</div>
