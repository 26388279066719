import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { VideoCallService } from '../../api-client';
import { ConfigurationProvider } from 'src/app/common/configuration-provider';
import { ConfigurationResponseModel } from 'src/app/api-client/model/configurationResponseModel';


@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.css']
})
export class ConsentComponent implements OnInit {
  private appUrl = '';
  publicMeetingId: any = null;
  paitentName: any = null;
  serviceType: any = null;
  host: any = null;
  roomMeetingId: any = null;
  scheduleDateTime: any = null;
  duration: any = null;
  


  constructor(private router: Router,
    private _activeRoute: ActivatedRoute,
    private configProvider: ConfigurationProvider) { }


  async ngOnInit() {
    this.getQueryStringFromUrl();
    let result = await this.configProvider.getConfigurationByKey('appUrl');
    this.appUrl = result ? result : '';
  }

  getQueryStringFromUrl() {
    this._activeRoute.queryParams.subscribe((params: any) => {
      if (params.u && params.paitentName && params.serviceType && params.host) {
        this.publicMeetingId = params.u;
        this.paitentName = params.paitentName;
        this.serviceType = params.serviceType;
        this.host = params.host;
        this.roomMeetingId = params.meetingRoomId;
      } else {
        this.router.navigate(['invalid-meeting']);
      }
      
      
    });
  }

  accept() {
    if (this.serviceType == 'Acs') {
      window.location.replace(`${this.host}/p/${this.roomMeetingId}`);
    }
    else if (this.serviceType == 'Jitsi') {
      window.location.replace(`${this.appUrl}/videovisit.html?i=${this.publicMeetingId}&d=${this.host}`);
    }
  }

}
