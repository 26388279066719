<!-- Loading wrapper start -->
<div id="loading-wrapper">
    <div class="spinner-border"></div>
</div>
<!-- Loading wrapper end -->

<!-- Page wrapper start -->
<div class="page-wrapper">
    <router-outlet></router-outlet>
</div>
<!-- Page wrapper end -->