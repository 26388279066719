/**
 * RenalIQ Communication API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CommunicationHistoryFilter } from './communicationHistoryFilter';
import { Limit } from './limit';

export interface CommunicationHistoryReferenceRequest extends CommunicationHistoryFilter { 
    applicationRefId?: string;
}