import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { CommonConstants } from '../../../shared/common-constants';
declare const $: any;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {

  isVisibleSetting: boolean = true;
  isVisibleChat: boolean = false;
  isVisibleContact: boolean = false;

  constructor(private authService: MsalService, private router: Router,) { }

  ngOnInit(): void {
    var username = localStorage.getItem(CommonConstants.LS_USERNAME);
    if (username == CommonConstants.DEAFULT_USERNAME) {
     // this.isVisibleSetting = true;
    }


  }



  public async menuToggle(): Promise<void> {
    if ($('body').hasClass('is-menu')) {
      $('body').removeClass('is-menu');
    } else {
      $('body').addClass('is-menu');
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

}


