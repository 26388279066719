/**
 * RenalIQ Communication API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BaseViewModel } from './baseViewModel';

export interface ScheduleMeetingViewModel extends BaseViewModel { 
    applicationId?: string;
    applicationPrimaryRefId?: string;
    applicationSecondaryRefId?: string;
    scheduleType?: string;
    meetingTitle?: string;
    scheduleStartDateTime: Date;
    scheduleEndDateTime: Date;
    mobileNo?: string;
    emailId?: string;
    organizerEmailId?: string;
    data?: { [key: string]: string; };
    privateMeetingLink?: string;
    privateMeetingId?: string;
    publicMeetingLink?: string;
    publicMeetingId?: string;
    onlineMeetingHost?: string;
    serviceType?: string;
    patientName?: string;
    currentUserName?: string;
    duration: number;
    status?: string;
}