import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants } from '../../../shared/common-constants';
declare const $: any;
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit {
  constructor(private router: Router,) {}

  ngOnInit(): void {
    $('[data-toggle="nav-tooltip"]').tooltip({ boundary: 'window' });
    var username = localStorage.getItem(CommonConstants.LS_USERNAME);
    //if (username != CommonConstants.DEAFULT_USERNAME) {
    //  this.router.navigate(['/dashboard']);
    //}
  }

  public async menuToggle(): Promise<void> {
    if ($('body').hasClass('is-menu')) {
      $('body').removeClass('is-menu');
    } else {
      $('body').addClass('is-menu');
    }
  }
}
