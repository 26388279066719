<section class="section section--video">
  <main class="section__main">
      <div class="section__header">
          <div class="container">
              <img class="section__header-logo" src="../../../assets/img/somatus.png" alt="Somatus"/>
          </div>
      </div>
      <div class="container">
        <div class="section__bigtitle mb-4">Unsubscribe</div>
        <div class="mb-3 ">Are you sure you want to unsubscribe your email from appointment-related notifications?</div>
        <div class="my-4">
          <button class="btn btn-lg btn-default px-4 px-sm-5 mr-3" (click)="notUnsubscribe()">NO</button>
          <button class="btn btn-lg btn-info px-4 px-sm-5" (click)="unsubscribe()">YES</button>
        </div>
    </div>
  </main>
</section>