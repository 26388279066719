import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingComponent } from './components/setting/setting.component';
import { TelehealthSettingComponent } from './components/telehealth-setting/telehealth-setting.component';
import { SmsSettingComponent } from './components/sms-setting/sms-setting.component';
import { EmailSettingComponent } from './components/email-setting/email-setting.component';
import { CommonSettingComponent } from './components/common-setting/common-setting.component';

const routes: Routes = [
  {
    path: '', component: SettingComponent, children: [
      { path: '', redirectTo: 'sms', pathMatch: 'prefix' },
      { path: 'telehealths', component: TelehealthSettingComponent },
      { path: 'sms', component: SmsSettingComponent },
      { path: 'email', component: EmailSettingComponent },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: []
})
export class SettingRoutingModule { }

export const SettingRoutingDeclarations = [
  SettingComponent,
  TelehealthSettingComponent,
  SmsSettingComponent,
  EmailSettingComponent,
  CommonSettingComponent
]
