<section class="section section--video">
    <main class="section__main">
        <div class="section__header">
            <div class="container">
                <img class="section__header-logo" src="../../../assets/img/somatus.png" alt="Somatus"/>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-10">
                    <div class="section__title mb-3 mb-md-4">Consent for Telehealth Service</div>
                    <div class="mb-3 ">Somatus normally provides an in-person assessment by a nurse practitioner in your home. However, due to the public health emergency with COVID-19, we are not conducting these in- home health services in person. Rather, we are offering members the opportunity to have these health services done with a nurse practitioner via telehealth. The telehealth services will be conducted using both audio and video connectivity and will be recorded for quality assurance.</div>
                    <div class="section__subtitle mb-2">OTHER AUDIO VIDEO SERVICES:</div>
                    <div class="mb-3">In addition to the telehealth services described above, there are care coordination and education services (“care management services”) our nurse practitioners and other care team members provide. Due to COVID-19, we have suspended the in-person option and are also offering these services through our audio and video service.
                        <br/>
                        By clicking on Accept button, you:
                    </div>
                    <ul class="list mb-2">
                        <li>Understand the content of these terms; and</li>
                        <li>Consent to Somatus telehealth services with a nurse practitioner.</li>
                    </ul>
                </div>
            </div>
            
            <div class="my-4">
                <button class="btn btn-lg btn-default px-4 px-sm-5 mr-3">CANCEL</button>
                <button class="btn btn-lg btn-info px-4 px-sm-5" (click)="accept()">ACCEPT</button>
            </div>
        </div>
    </main>
</section>
