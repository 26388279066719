<section class="section section--video">
  <main class="section__main">
      <div class="section__header">
          <div class="container">
              <img class="section__header-logo" src="../../../assets/img/somatus.png" alt="Somatus"/>
          </div>
      </div>
      <div class="container">
        <div class="section__bigtitle mb-4">Welcome to Somatus<br/>Telehealth Service</div>
        <div *ngIf="scheduleInfo">
         
          <button class="btn btn-primary" type="button" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Please Wait...
          </button>
        </div>
        <div *ngIf="scheduleInfo === null">
          You don't have any meeting scheduled in the next 15 mins. 
          <div class="mt-2 fs-14">Please check with Somatus CareTeam for your meeting time</div>
        </div>
    </div>
  </main>
</section>