/**
 * RenalIQ Communication API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BaseViewModel } from './baseViewModel';

export interface AppServiceTemplateViewModel extends BaseViewModel { 
    appServiceMappingId: string;
    name?: string;
    type?: string;
    subject?: string;
    body?: string;
    inputParameters?: string;
    isActive: boolean;
}