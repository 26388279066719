<section class="section section--video">
    <main class="section__main text-center">
        <div class="section__header">
            <div class="container">
                <img class="section__header-logo" src="../../../assets/img/somatus.png" alt="Somatus"/>
            </div>
        </div>
        <div class="container">
          <div class="mb-3 ">You have successfully unsubscribed your email.</div>
          <div class="my-4">
            <button class="btn btn-lg btn-info px-4 px-sm-5" (click)="goToSomatusHome()">Go To Somatus Home</button>
          </div>
      </div>
    </main>
  </section>