<section class="section">
    <main class="section__main">
        <div class="section__header">
            <div class="container-fluid">
                <img class="section__header-logo" src="../../../assets/img/somatus.png" alt="Somatus" />
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-9 order-lg-2">
                    <div class="section__video-panel">
                        <!-- <div class="fs-16 mb-2 pb-1 text-truncate fw-bold">Recording starded from 27 July 2022, 10:00 AM to 27 July 2022, 10:30 AM</div> -->
                        <div class="row g-2">
                            <div class="col-12 col-lg-9">
                                <video controls autoplay #video width="100%">
                                    <source [src]="videoPlayed" type="video/mp4;">
                                </video>
                            </div>
                            <div class="col-12 col-lg-3 mt-2 mt-lg-0">
                                <ul class="video-info">
                                    <li class="video-info__item w-100">
                                        <div class="fs-18 text-truncate fw-bold">Meeting Recordings</div>
                                    </li>
                                    <li class="video-info__item w-100" *ngFor="let listItem of playList">
                                        <a class="video-info__link" (click)="videoThumbnailHandler(listItem)">
                                            {{listItem.name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div *ngIf="!recordingAvailable">
                            <p>Recording not available.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-3 order-lg-1 mt-3 mt-lg-0">
                    <app-meeting-info></app-meeting-info>
                </div>
            </div>
        </div>
    </main>
</section>