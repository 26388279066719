import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(private _router: Router,) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.includes("https://graph.microsoft.com/v1.0/me")
      || this._router.url.includes("https://graph.microsoft.com/v1.0/me")) {
      return next.handle(req);
    }

    if (this._router.url.includes("/telehealth") || this._router.url.includes("/consent")
      || this._router.url.includes("/joining-link")) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {

          if ((error.status == 401 || error.status == 403)) {
            // pass to next interceptor
            // return next.handle(req);
            this._router.navigateByUrl("/unauthorized");
            // console.log("Unauthorized");   
          }
        } else {

        }
        throw error;
      }));
  }

}
