<section class="section section--video">
    <main class="section__main">
        <div class="section__header">
            <div class="container">
                <img class="section__header-logo" src="../../../assets/img/somatus.png" alt="Somatus"/>
            </div>
        </div>
        <div class="container">
          <div class="section__bigtitle mb-4">Error</div>
          <div class="mb-3 ">Invalid meeting id.</div>
      </div>
    </main>
</section>