import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AppMasterService } from './api/appMaster.service';
import { AppServiceTemplateService } from './api/appServiceTemplate.service';
import { CacheService } from './api/cache.service';
import { ChatService } from './api/chat.service';
import { CommunicationService } from './api/communication.service';
import { HistoryService } from './api/history.service';
import { MappingService } from './api/mapping.service';
import { MeetingStatisticsService } from './api/meetingStatistics.service';
import { ProviderService } from './api/provider.service';
import { ProvidersConfigService } from './api/providersConfig.service';
import { ServiceMasterService } from './api/serviceMaster.service';
import { UserMasterService } from './api/userMaster.service';
import { VideoCallService } from './api/videoCall.service';
import { VideoCallFeedbackService } from './api/videoCallFeedback.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AppMasterService,
    AppServiceTemplateService,
    CacheService,
    ChatService,
    CommunicationService,
    HistoryService,
    MappingService,
    MeetingStatisticsService,
    ProviderService,
    ProvidersConfigService,
    ServiceMasterService,
    UserMasterService,
    VideoCallService,
    VideoCallFeedbackService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<any> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
