<!-- Start Container -->
<div class="main__content">
  <div class="d-flex">
    <div class="flex-fill">
      <div class="main__header">Setting</div>
    </div>
    <div><a class="btn btn-sm btn-outline-third px-4" href="#">Close</a></div>
  </div>
  <div class="main__subheader">{{ title }} Setting</div>

  <form>
    <div class="row">
      <div class="col-12">
        <div class="fw-500">Mode</div>
        <div class="custom-control custom-radio custom-control-inline my-3">
          <input
            type="radio"
            id="customRadio1"
            name="customRadio"
            class="custom-control-input"
            [checked]="isActive"
            (change)="onchangeActive($event)"
          />
          <label class="custom-control-label" for="customRadio1">Active</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline my-3">
          <input
            type="radio"
            id="customRadio2"
            name="customRadio"
            class="custom-control-input"
            [checked]="isPassiveActive"
            (change)="onchangeActivePassive($event)"
          />
          <label class="custom-control-label" for="customRadio2"
            >Active-Passive</label
          >
        </div>
      </div>
      <div class="col-12">
        <div class="row g-2" *ngIf="isActive">
          <div class="col-12">
            <div class="p-3 bg-lightblue mb-3 border-top-lighten rounded">
              <div class="row g-2">
                <div class="col-12 col-sm-10">
                  <label for="primaryService">Service 1</label>
                  <select
                    class="custom-select"
                    id="primaryService"
                    name="primaryService"
                    [(ngModel)]="requestModel.primaryServiceId"
                    #primaryService="ngModel"
                  >
                    <!--<option selected>select</option>-->
                    <option
                      *ngFor="let item of listOfProviders"
                      [value]="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-sm-2 pt-3 pt-sm-0">
                  <label for="serviceStatus">Weight</label>
                  <select
                    class="custom-select"
                    id="serviceStatus"
                    name="serviceweight"
                    [(ngModel)]="requestModel.primaryServicePriority"
                    #serviceweight="ngModel"
                    (change)="changeService1Weight(serviceweight.value)"
                  >
                    <option value="1">10%</option>
                    <option value="2">20%</option>
                    <option value="3">30%</option>
                    <option value="4">40%</option>
                    <option value="5">50%</option>
                    <option value="6">60%</option>
                    <option value="7">70%</option>
                    <option value="8">80%</option>
                    <option value="9">90%</option>
                    <!--<option value="10">100%</option>-->
                  </select>
                </div>
              </div>
            </div>
            <div
              class="p-3 bg-lightblue mb-3 border-top-lighten rounded"
              *ngIf="listOfProviders.length > 1"
            >
              <div class="row g-2">
                <div class="col-12 col-sm-10">
                  <label for="secondaryService">Service 2</label>
                  <select
                    class="custom-select"
                    id="secondaryService"
                    name="secondaryService"
                    [(ngModel)]="requestModel.secondaryServiceId"
                    #secondaryService="ngModel"
                  >
                    <!--<option selected>select</option>-->
                    <option
                      *ngFor="let item of listOfProviders"
                      [value]="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-sm-2 pt-3 pt-sm-0">
                  <label for="serviceStatus">Weight</label>
                  <input
                    class="form-control"
                    type="text"
                    id="weight2"
                    name="weighttwo"
                    [(ngModel)]="secondServiceProirty"
                    #weighttwo="ngModel"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row g-2" *ngIf="isPassiveActive">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="primaryService">Primary Service</label>
              <select
                class="custom-select"
                id="primaryService"
                name="primaryService"
                [(ngModel)]="requestModel.primaryServiceId"
                #primaryService="ngModel"
              >
                <!--<option selected>select</option>-->
                <option *ngFor="let item of listOfProviders" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-12 col-md-6" *ngIf="listOfProviders.length > 1">
            <div class="form-group">
              <label for="secondaryService">Secondary Service</label>
              <select
                class="custom-select"
                id="secondaryService"
                name="secondaryService"
                [(ngModel)]="requestModel.secondaryServiceId"
                #secondaryService="ngModel"
              >
                <!--<option selected>select</option>-->
                <option *ngFor="let item of listOfProviders" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4">
        <div class="form-group mb-2">
          <label for="serviceStatus">Retry Count to check service status</label>

          <select
            class="custom-select"
            id="serviceStatus"
            name="serviceStatus"
            [(ngModel)]="requestModel.serviceStatusRetry"
            #serviceStatus="ngModel"
          >
            <option value="1">01</option>
            <option value="2">02</option>
            <option value="3">03</option>
            <option value="4">04</option>
          </select>
        </div>
      </div>
    </div>
  </form>

  <hr />
  <div class="text-right">
    <button class="btn btn-third px-4" (click)="save()">Save</button>
  </div>
</div>
<!-- End Container -->
