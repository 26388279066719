import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { VideoCallService } from '../../api-client';
import { AppInsightsService } from 'src/app/app-insights-service';
import { CommonConstants } from 'src/app/shared/common-constants';

@Component({
  selector: 'app-joning-link',
  templateUrl: './joining-link.component.html',
  styleUrls: ['./joining-link.component.css']
})
export class JoiningLinkComponent implements OnInit {

  privateMeetingId: any = null;
  scheduleInfo: any = {};
  payload : any;

  constructor(private router: Router,
    private _activeRoute: ActivatedRoute,
    private _videocallService: VideoCallService,
    private appInsights : AppInsightsService
  ) { }

  ngOnInit(): void {
    this.getQueryStringFromUrl();
  }

  getQueryStringFromUrl() {
    this._activeRoute.queryParams.subscribe((params: any) => {
      if (!params.i) this.router.navigate(['invalid-meeting']);
      this.privateMeetingId = params.i;
      this.payload = {
        "timeStamp": Date.now,
        "message":"Redirected from RenalIQ Scheduler",
        "useremail":localStorage.getItem(CommonConstants.LS_USERNAME)!,
        "username":localStorage.getItem(CommonConstants.LS_NAME)!,
        "sessionid":localStorage.getItem(CommonConstants.LS_SESSION)!,
        "meetingId":this.privateMeetingId
      }
      this.appInsights.trackPageView("Telehealth Meeting",window.location.href,this.payload);
      if (this.privateMeetingId != null) {
        this.getScheduleInfo(this.privateMeetingId);
      }

    });
  }

  async getScheduleInfo(meetingId: string) {
    let that = this;
    that.scheduleInfo = await this._videocallService.videoCallGetHostByMeetingId(meetingId).toPromise();
    if (that.scheduleInfo) {
      let patientName = that.scheduleInfo.data["patientName"];
      let userName = that.scheduleInfo.data["caregiverName"];
      console.log(that.scheduleInfo);

      this.payload.message = "Redirecting to Telehealth";
      this.appInsights.trackEvent("Meeting found",this.payload);
      if (that.scheduleInfo.serviceName == 'Acs') {
        window.location.replace(`${that.scheduleInfo.host}/c/${that.scheduleInfo.meetingRoomId}`);
      }
      else {
        window.location.replace(`${environment.appUrl}/videocall.html?i=${meetingId}&p=${patientName}&c=${userName}&s=${that.scheduleInfo.scheduleTime}&h=${that.scheduleInfo.host}`);
      }
    }
    else {
      this.payload.message = "Meeting not scheduled in next 15 min.";
      this.appInsights.trackEvent("Meeting Not Found",this.payload);
      console.log("meeting not found.")
    }
  }





}
