import { Component, OnInit } from '@angular/core';
import { ServiceMasterService } from '../../../api-client';
import { CommonConstants } from '../../../shared/common-constants';

@Component({
  selector: 'app-sms-setting',
  templateUrl: './sms-setting.component.html',
  styleUrls: ['./sms-setting.component.scss']
})
export class SmsSettingComponent implements OnInit {

  serviceMasterId: any; // = CommonConstants.SMS_SERVICEMASTERID

  constructor(private _serviceMasterService: ServiceMasterService,) { }

  ngOnInit(): void {
    this.getAll();
  }
  public async getAll() {
    let services: any = await this._serviceMasterService.serviceMasterGetAll().toPromise();
    let obj = services.find((x:any) => x.type == 'SMS');
    this.serviceMasterId = obj.id;

    console.log(services);
  }


}
