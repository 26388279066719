import { Component } from '@angular/core';
import { ConfigurationProvider } from './common/configuration-provider';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'azurecommunication-web';
  
  constructor(private configuarationProvider : ConfigurationProvider){}

  ngOnInit(){
    this.configuarationProvider.init();
  }
}
