/**
 * RenalIQ Communication API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BaseRequestModel } from '../model/baseRequestModel';
import { IVideoRecordingResponse } from '../model/iVideoRecordingResponse';
import { MeetingStatsRequest } from '../model/meetingStatsRequest';
import { ScheduleMeetingViewModel } from '../model/scheduleMeetingViewModel';
import { UserEnvironmentModel } from '../model/userEnvironmentModel';
import { VideoCallRequest } from '../model/videoCallRequest';
import { VideoCallTestRequest } from '../model/videoCallTestRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VideoCallService {

    protected basePath = 'http://localhost:5000/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param serviceProvider 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallAssignHost(serviceProvider: string, meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public videoCallAssignHost(serviceProvider: string, meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public videoCallAssignHost(serviceProvider: string, meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public videoCallAssignHost(serviceProvider: string, meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serviceProvider === null || serviceProvider === undefined) {
            throw new Error('Required parameter serviceProvider was null or undefined when calling videoCallAssignHost.');
        }

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallAssignHost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/VideoCall/${encodeURIComponent(String(serviceProvider))}/assign/host/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallCallBackURL(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallCallBackURL(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallCallBackURL(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallCallBackURL(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/callback`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallCancelMeeting(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallCancelMeeting(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallCancelMeeting(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallCancelMeeting(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallCancelMeeting.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/cancel/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallConsent(body: BaseRequestModel, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public videoCallConsent(body: BaseRequestModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public videoCallConsent(body: BaseRequestModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public videoCallConsent(body: BaseRequestModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling videoCallConsent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/api/VideoCall/Consent`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallDeAllocationHost(observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallDeAllocationHost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallDeAllocationHost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallDeAllocationHost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/logicapp/deallocationhost`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallDeleteRecordingChunks(startDate: Date, endDate: Date, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallDeleteRecordingChunks(startDate: Date, endDate: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallDeleteRecordingChunks(startDate: Date, endDate: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallDeleteRecordingChunks(startDate: Date, endDate: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (startDate === null || startDate === undefined) {
            throw new Error('Required parameter startDate was null or undefined when calling videoCallDeleteRecordingChunks.');
        }

        if (endDate === null || endDate === undefined) {
            throw new Error('Required parameter endDate was null or undefined when calling videoCallDeleteRecordingChunks.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/DeleteRecordingChunks/${encodeURIComponent(String(startDate))}/${encodeURIComponent(String(endDate))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param serviceProvider 
     * @param userid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallGetAuthToken(serviceProvider: string, userid: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallGetAuthToken(serviceProvider: string, userid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallGetAuthToken(serviceProvider: string, userid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallGetAuthToken(serviceProvider: string, userid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serviceProvider === null || serviceProvider === undefined) {
            throw new Error('Required parameter serviceProvider was null or undefined when calling videoCallGetAuthToken.');
        }

        if (userid === null || userid === undefined) {
            throw new Error('Required parameter userid was null or undefined when calling videoCallGetAuthToken.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/${encodeURIComponent(String(serviceProvider))}/GetAuthToken/${encodeURIComponent(String(userid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallGetHostByMeetingId(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallGetHostByMeetingId(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallGetHostByMeetingId(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallGetHostByMeetingId(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallGetHostByMeetingId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/getavailablehost/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallGetMeetingInfo(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallGetMeetingInfo(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallGetMeetingInfo(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallGetMeetingInfo(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallGetMeetingInfo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/get/meetinginfo/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallGetMeetingUserEnvironment(meetingId?: string, userId?: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public videoCallGetMeetingUserEnvironment(meetingId?: string, userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public videoCallGetMeetingUserEnvironment(meetingId?: string, userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public videoCallGetMeetingUserEnvironment(meetingId?: string, userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (meetingId !== undefined && meetingId !== null) {
            queryParameters = queryParameters.set('meetingId', <any>meetingId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/VideoCall/meeting/user/environment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallGetRecordingByMeetingId(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallGetRecordingByMeetingId(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallGetRecordingByMeetingId(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallGetRecordingByMeetingId(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallGetRecordingByMeetingId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/GetRecording/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallGetRecordingListByMeetingId(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallGetRecordingListByMeetingId(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallGetRecordingListByMeetingId(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallGetRecordingListByMeetingId(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallGetRecordingListByMeetingId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/GetRecording/list/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallGetRecordingMemoryStreamByMeetingId(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallGetRecordingMemoryStreamByMeetingId(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallGetRecordingMemoryStreamByMeetingId(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallGetRecordingMemoryStreamByMeetingId(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallGetRecordingMemoryStreamByMeetingId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/recording/memorystream/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param serviceProvider 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallGetRooms(serviceProvider: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallGetRooms(serviceProvider: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallGetRooms(serviceProvider: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallGetRooms(serviceProvider: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serviceProvider === null || serviceProvider === undefined) {
            throw new Error('Required parameter serviceProvider was null or undefined when calling videoCallGetRooms.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/Rooms/${encodeURIComponent(String(serviceProvider))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param serviceProvider 
     * @param roomid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallGetScheduleInfo(serviceProvider: string, roomid: string, observe?: 'body', reportProgress?: boolean): Observable<ScheduleMeetingViewModel>;
    public videoCallGetScheduleInfo(serviceProvider: string, roomid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ScheduleMeetingViewModel>>;
    public videoCallGetScheduleInfo(serviceProvider: string, roomid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ScheduleMeetingViewModel>>;
    public videoCallGetScheduleInfo(serviceProvider: string, roomid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serviceProvider === null || serviceProvider === undefined) {
            throw new Error('Required parameter serviceProvider was null or undefined when calling videoCallGetScheduleInfo.');
        }

        if (roomid === null || roomid === undefined) {
            throw new Error('Required parameter roomid was null or undefined when calling videoCallGetScheduleInfo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ScheduleMeetingViewModel>('get',`${this.basePath}/api/VideoCall/${encodeURIComponent(String(serviceProvider))}/meeting/info/${encodeURIComponent(String(roomid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param serviceProvider 
     * @param meetingId 
     * @param serverCallId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallInitSession(serviceProvider: string, meetingId: string, serverCallId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public videoCallInitSession(serviceProvider: string, meetingId: string, serverCallId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public videoCallInitSession(serviceProvider: string, meetingId: string, serverCallId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public videoCallInitSession(serviceProvider: string, meetingId: string, serverCallId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serviceProvider === null || serviceProvider === undefined) {
            throw new Error('Required parameter serviceProvider was null or undefined when calling videoCallInitSession.');
        }

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallInitSession.');
        }

        if (serverCallId === null || serverCallId === undefined) {
            throw new Error('Required parameter serverCallId was null or undefined when calling videoCallInitSession.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/VideoCall/${encodeURIComponent(String(serviceProvider))}/initSession/${encodeURIComponent(String(meetingId))}/${encodeURIComponent(String(serverCallId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param serviceProvider 
     * @param userid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallRefreshToken(serviceProvider: string, userid: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallRefreshToken(serviceProvider: string, userid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallRefreshToken(serviceProvider: string, userid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallRefreshToken(serviceProvider: string, userid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serviceProvider === null || serviceProvider === undefined) {
            throw new Error('Required parameter serviceProvider was null or undefined when calling videoCallRefreshToken.');
        }

        if (userid === null || userid === undefined) {
            throw new Error('Required parameter userid was null or undefined when calling videoCallRefreshToken.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/${encodeURIComponent(String(serviceProvider))}/RefreshToken/${encodeURIComponent(String(userid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param serviceProvider 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallSaveMeetingStats(body: MeetingStatsRequest, serviceProvider: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public videoCallSaveMeetingStats(body: MeetingStatsRequest, serviceProvider: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public videoCallSaveMeetingStats(body: MeetingStatsRequest, serviceProvider: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public videoCallSaveMeetingStats(body: MeetingStatsRequest, serviceProvider: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling videoCallSaveMeetingStats.');
        }

        if (serviceProvider === null || serviceProvider === undefined) {
            throw new Error('Required parameter serviceProvider was null or undefined when calling videoCallSaveMeetingStats.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/api/VideoCall/${encodeURIComponent(String(serviceProvider))}/statistics/save`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallSaveMeetingUserEnvironment(body: UserEnvironmentModel, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public videoCallSaveMeetingUserEnvironment(body: UserEnvironmentModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public videoCallSaveMeetingUserEnvironment(body: UserEnvironmentModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public videoCallSaveMeetingUserEnvironment(body: UserEnvironmentModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling videoCallSaveMeetingUserEnvironment.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/VideoCall/save/meeting/user/environment`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param serviceProvider 
     * @param documentId 
     * @param documentLocation 
     * @param metaLocation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallSaveRecordingFile(serviceProvider: string, documentId?: string, documentLocation?: string, metaLocation?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public videoCallSaveRecordingFile(serviceProvider: string, documentId?: string, documentLocation?: string, metaLocation?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public videoCallSaveRecordingFile(serviceProvider: string, documentId?: string, documentLocation?: string, metaLocation?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public videoCallSaveRecordingFile(serviceProvider: string, documentId?: string, documentLocation?: string, metaLocation?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serviceProvider === null || serviceProvider === undefined) {
            throw new Error('Required parameter serviceProvider was null or undefined when calling videoCallSaveRecordingFile.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }
        if (documentLocation !== undefined && documentLocation !== null) {
            queryParameters = queryParameters.set('documentLocation', <any>documentLocation);
        }
        if (metaLocation !== undefined && metaLocation !== null) {
            queryParameters = queryParameters.set('metaLocation', <any>metaLocation);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/VideoCall/${encodeURIComponent(String(serviceProvider))}/saveRecordingFile`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallSaveRecordingFileAcsEvent(body: any, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallSaveRecordingFileAcsEvent(body: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallSaveRecordingFileAcsEvent(body: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallSaveRecordingFileAcsEvent(body: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling videoCallSaveRecordingFileAcsEvent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/VideoCall/event/saveAcsRecordingFile`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallScheduleInfo(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallScheduleInfo(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallScheduleInfo(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallScheduleInfo(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallScheduleInfo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/ScheduleInfo/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallScheduleMeeting(body: VideoCallRequest, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallScheduleMeeting(body: VideoCallRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallScheduleMeeting(body: VideoCallRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallScheduleMeeting(body: VideoCallRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling videoCallScheduleMeeting.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/VideoCall/schedule/meeting`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param serviceProvider 
     * @param meetingId 
     * @param userType 
     * @param serverCallId 
     * @param userId 
     * @param paticipantId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallStartRecording(serviceProvider: string, meetingId: string, userType: string, serverCallId: string, userId: string, paticipantId: string, observe?: 'body', reportProgress?: boolean): Observable<IVideoRecordingResponse>;
    public videoCallStartRecording(serviceProvider: string, meetingId: string, userType: string, serverCallId: string, userId: string, paticipantId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IVideoRecordingResponse>>;
    public videoCallStartRecording(serviceProvider: string, meetingId: string, userType: string, serverCallId: string, userId: string, paticipantId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IVideoRecordingResponse>>;
    public videoCallStartRecording(serviceProvider: string, meetingId: string, userType: string, serverCallId: string, userId: string, paticipantId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serviceProvider === null || serviceProvider === undefined) {
            throw new Error('Required parameter serviceProvider was null or undefined when calling videoCallStartRecording.');
        }

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallStartRecording.');
        }

        if (userType === null || userType === undefined) {
            throw new Error('Required parameter userType was null or undefined when calling videoCallStartRecording.');
        }

        if (serverCallId === null || serverCallId === undefined) {
            throw new Error('Required parameter serverCallId was null or undefined when calling videoCallStartRecording.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling videoCallStartRecording.');
        }

        if (paticipantId === null || paticipantId === undefined) {
            throw new Error('Required parameter paticipantId was null or undefined when calling videoCallStartRecording.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IVideoRecordingResponse>('get',`${this.basePath}/api/VideoCall/${encodeURIComponent(String(serviceProvider))}/StartRecording/${encodeURIComponent(String(meetingId))}/${encodeURIComponent(String(userType))}/${encodeURIComponent(String(serverCallId))}/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(paticipantId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallTempCallLink(body: VideoCallTestRequest, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public videoCallTempCallLink(body: VideoCallTestRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public videoCallTempCallLink(body: VideoCallTestRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public videoCallTempCallLink(body: VideoCallTestRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling videoCallTempCallLink.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<string>('post',`${this.basePath}/api/VideoCall/getTempCallLink`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallTestScheduleInfo(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public videoCallTestScheduleInfo(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public videoCallTestScheduleInfo(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public videoCallTestScheduleInfo(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallTestScheduleInfo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/VideoCall/test/scheduleinfo/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param serviceProvider 
     * @param meetingId 
     * @param participantId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallUpdateMeetingRecording(serviceProvider: string, meetingId: string, participantId: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public videoCallUpdateMeetingRecording(serviceProvider: string, meetingId: string, participantId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public videoCallUpdateMeetingRecording(serviceProvider: string, meetingId: string, participantId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public videoCallUpdateMeetingRecording(serviceProvider: string, meetingId: string, participantId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (serviceProvider === null || serviceProvider === undefined) {
            throw new Error('Required parameter serviceProvider was null or undefined when calling videoCallUpdateMeetingRecording.');
        }

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallUpdateMeetingRecording.');
        }

        if (participantId === null || participantId === undefined) {
            throw new Error('Required parameter participantId was null or undefined when calling videoCallUpdateMeetingRecording.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/VideoCall/${encodeURIComponent(String(serviceProvider))}/recording/status/${encodeURIComponent(String(meetingId))}/${encodeURIComponent(String(participantId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public videoCallUpdateStatus(meetingId: string, status: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public videoCallUpdateStatus(meetingId: string, status: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public videoCallUpdateStatus(meetingId: string, status: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public videoCallUpdateStatus(meetingId: string, status: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling videoCallUpdateStatus.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling videoCallUpdateStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/VideoCall/UpdateStatus/${encodeURIComponent(String(meetingId))}/${encodeURIComponent(String(status))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
