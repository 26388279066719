import { Component, OnInit } from '@angular/core';
import { ServiceMasterService } from '../../../api-client';
import { CommonConstants } from '../../../shared/common-constants';

@Component({
  selector: 'app-telehealth-setting',
  templateUrl: './telehealth-setting.component.html',
  styleUrls: ['./telehealth-setting.component.scss']
})
export class TelehealthSettingComponent implements OnInit {

  serviceMasterId: any; // = CommonConstants.TELEHEATH_SERVICEMASTERID;

  constructor(private _serviceMasterService: ServiceMasterService,) { }

  ngOnInit(): void {
    this.getAll();
  }
  public async getAll() {
    let services: any = await this._serviceMasterService.serviceMasterGetAll().toPromise();
    let obj = services.find((x: any) => x.type == 'Video');
    this.serviceMasterId = obj.id;
  }

}
