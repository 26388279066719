import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsentComponent } from './consent/consent.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TelehealthComponent } from './telehealth/telehealth.component';
import { JoiningLinkComponent } from './joining-link/joining-link.component';




@NgModule({
  declarations: [ConsentComponent, FeedbackComponent, TelehealthComponent, JoiningLinkComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [ConsentComponent, FeedbackComponent, TelehealthComponent, JoiningLinkComponent]
})
export class VideoChatModule { }
