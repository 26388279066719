<div class="main">
  <app-navigation></app-navigation>
  <router-outlet></router-outlet>
  <!-- <aside class="sidebar-wrapper">
    <div class="sidebar-tabs">
      <app-navigation></app-navigation>
    </div>
  </aside>
  <div class="main-container">
    <router-outlet></router-outlet>
  </div> -->
</div>
