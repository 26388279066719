/**
 * RenalIQ Communication API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BaseRequestModel } from './baseRequestModel';

export interface VideoCallRequest extends BaseRequestModel { 
    applicationPrimaryRefId?: string;
    applicationSecondaryRefId?: string;
    meetingTitle: string;
    scheduleType: number;
    scheduleStartDateTime: Date;
    scheduleEndDateTime: Date;
    mobileNo: string;
    emailId?: string;
    organizerEmailId?: string;
    data?: { [key: string]: string; };
    meetingId?: string;
    testing: boolean;
}