export class CommonConstants {

  public static EMAIL_SERVICEMASTERID: string = "4404C848-702C-4CB7-BF4D-83E575A88EC8";
  public static SMS_SERVICEMASTERID: string = "900313A6-D324-4D90-8DC1-6884EA82F6C4";
  public static TELEHEATH_SERVICEMASTERID: string = "A72A64CF-11FD-4F90-8EFF-A3F732FDC814";


  public static PRIMARY_PROIRTY: string = "P";
  public static SECONDARY_PROIRTY: string = "S";

  public static LS_USERNAME: string = "USERNAME";
  public static readonly LS_NAME: string = "NAME";
  public static readonly LS_SESSION: string = "SESSION_ID";
  public static DEAFULT_USERNAME: string = "hsadmin@esolutionsfirst.com";
  public static LS_USEROLE: string = "USEROLE";

  public static CAHCH_APP_MASTER: string = "_AppMaster";
  public static CAHCH_APP_SERVICE: string = "_AppService";
  public static CAHCH_APP_SERVICE_TEMPLATE: string = "_AppServiceTemplate";
  public static CAHCH_PROVIDER: string = "_Provider";
  public static CAHCH_PROVIDER_CONFIG: string = "_ProviderConfig";
  public static CAHCH_SERVICE_MASTER: string = "_ServiceMaster";
  public static CAHCH_SERVICE_PROVIDER: string = "_ServiceProvider";
  public static CAHCH_USER_MASTER: string = "_UserMaster";
  public static CAHCH_NOTIFICATION_CATEGORY: string = "_NotificationCategory";
  public static CAHCH_USER_ROLE: string = "_UserRole";

  



}
