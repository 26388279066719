import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VideoCallService } from 'src/app/api-client';
import { MeetingInfo } from 'src/app/models/Api';

@Component({
  selector: 'app-meeting-info',
  templateUrl: './meeting-info.component.html',
  styleUrls: ['./meeting-info.component.scss']
})
export class MeetingInfoComponent implements OnInit {
  private meetingId: string = '';
  public meetingInfo: MeetingInfo | null = null;
  constructor(private route: ActivatedRoute,
    private videoCallService: VideoCallService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const { meetingId } = params;
      this.meetingId = meetingId;
      this.getMeetingInfo();
    });
  }
  getMeetingInfo() {
    this.videoCallService.videoCallGetMeetingInfo(this.meetingId)
    .subscribe((response: any) => {
      this.meetingInfo = response as MeetingInfo;
    });
  }

}
