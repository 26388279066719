<div class="login__wrap">
  <!--<img class="img-fluid" src="./assets/img/login.jpg" alt="Somatus">-->
  <div>
    <div class="text-center fs-16">Welcome back!</div>
    <h5 class="text-center mb-4">Please login your account</h5>
    <div class="text-center">
      <button class="btn btn-outline-dark btn-lg rounded-pill px-4" (click)="loginRedirect()">
        Sign In as Somatus Employee
      </button>
    </div>
  </div>
</div>




<!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
