<div class="aside__wrap">
  <!-- Start Aside Panel -->
  <div class="aside">
    <div class="aside__title">Communication</div>
    <div class="search-control">
      <input type="text" placeholder="Search System" class="form-control" />
    </div>
    <ul class="aside__tabs">
      <li class="aside__tabs-item">
        <a
          class="aside__tabs-link"
          routerLinkActive="is-active"
          routerLink="./sms"
        >
          SMS
          <span class="aside__tabs-info"
            >Last Sent On : 2022-02-05 10:21 AM</span
          >
          <span class="aside__tabs-icon">
            <!--img src="./assets/img/gear.svg" alt="Setting" /-->
          </span>
        </a>
      </li>
      <li class="aside__tabs-item">
        <a
          class="aside__tabs-link"
          routerLinkActive="is-active"
          routerLink="./email"
        >
          EMAIL
          <span class="aside__tabs-info">
            Last Sent On : 2022-02-05 10:21 AM
          </span>
          <span class="aside__tabs-icon">
            <!--img src="./assets/img/gear.svg" alt="Setting" /-->
          </span>
        </a>
      </li>
      <li class="aside__tabs-item">
        <a
          class="aside__tabs-link"
          routerLinkActive="is-active"
          routerLink="./telehealths"
        >
          Telehealth
          <span class="aside__tabs-info"
            >Last Call On : 2022-02-05 10:21 AM</span
          >
          <span class="aside__tabs-icon">
            <!--img src="./assets/img/gear.svg" alt="Setting" /-->
          </span>
        </a>
      </li>
    </ul>
  </div>

  <div class="aside__overlay" (click)="menuToggle()"></div>

  <!-- End Aside Panel -->
  <!-- Start Container -->
  <!--<div class="main__content">
    <div class="d-flex">
      <div class="flex-fill">
        <div class="main__header">Setting</div>
      </div>
      <div><a class="btn btn-sm btn-outline-third px-4" href="#">Close</a></div>
    </div>
    <div class="main__subheader">Telehealth Setting</div>

    <form>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="primaryService">Primary Service</label>
            <select class="custom-select" id="primaryService">
              <option selected>Primary Service 01</option>
              <option value="1">Primary Service 02</option>
              <option value="2">Primary Service 03</option>
              <option value="3">Primary Service 04</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="secondaryService">Secondary Service</label>
            <select class="custom-select" id="secondaryService">
              <option selected>Secondary Service 01</option>
              <option value="1">Secondary Service 02</option>
              <option value="2">Secondary Service 03</option>
              <option value="3">Secondary Service 04</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="custom-control custom-radio my-3">
            <input
              type="radio"
              id="customRadio1"
              name="customRadio"
              class="custom-control-input"
              checked
            />
            <label class="custom-control-label" for="customRadio1"
              >Primary: Active - Secondary: Active</label
            >
          </div>
          <div class="custom-control custom-radio my-3">
            <input
              type="radio"
              id="customRadio2"
              name="customRadio"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="customRadio2"
              >Switch to Secondary Service, if primary service stops</label
            >
          </div>
        </div>
        <div class="col-12 col-md-4 col-xl-3">
          <div class="form-group px-4">
            <label for="serviceStatus">Service Status Retry</label>
            <select class="custom-select" id="serviceStatus">
              <option selected>01</option>
              <option value="1">02</option>
              <option value="2">03</option>
              <option value="3">04</option>
            </select>
          </div>
        </div>
      </div>
    </form>

    <hr />
    <div class="text-right">
      <a class="btn btn-third px-4" href="#">Save</a>
    </div>
  </div>-->
  <!-- End Container -->
  <router-outlet></router-outlet>
</div>
