import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SettingRoutingDeclarations, SettingRoutingModule } from './setting-routing.module';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    SettingRoutingDeclarations
  ],
  imports: [
    CommonModule,
    RouterModule,
    SettingRoutingModule,
    FormsModule      
  ]
})
export class SettingModule { }
