import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';
import { VideoCallService } from '../api-client/api/videoCall.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { AppInsightsService } from '../app-insights-service';
import { CommonConstants } from '../shared/common-constants';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit {
  public playList: any = [
    // {
    //   name: 'Test Video1',
    //   url: '/assets/videos/production ID_5190548.mp4'
    // },
    // {
    //   name: 'Test Video2',
    //   url: '/assets/videos/production ID_4364504.mp4'
    // },
    // {
    //   name: 'Test Video3',
    //   url: '/assets/videos/production ID_5190548.mp4'
    // },
    // {
    //   name: 'Test Video4',
    //   url: '/assets/videos/production ID_4364504.mp4'
    // },
    // {
    //   name: 'Test Video3',
    //   url: '/assets/videos/production ID_5190548.mp4'
    // },
    // {
    //   name: 'Test Video4',
    //   url: '/assets/videos/production ID_4364504.mp4'
    // }
  ]
  public videoPlayed: any;
  public fullScreenVideo: boolean = false;
  private meetingId: string = '';
  public recordingAvailable: boolean = true;
  private timerRef: any;
  public payload : any;
  @ViewChild('video', { static: true }) public video!: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private videoCallService: VideoCallService,
    private appInsights : AppInsightsService) { }
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const { meetingId } = params;
      this.meetingId = meetingId;
      this.recordingAvailable = true;
      this.getVideoList();
    });
    this.payload = {
      "timeStamp": Date.now(),
      "meetingId": this.meetingId,
      "message":"Redirected from RenalIQ Scheduler",
      "useremail":localStorage.getItem(CommonConstants.LS_USERNAME)!,
      "username":localStorage.getItem(CommonConstants.LS_NAME)!,
      "sessionid":localStorage.getItem(CommonConstants.LS_SESSION)!,
    }
    this.appInsights.trackPageView("Telehealth Video Player",window.location.href,this.payload);
  }
  private getVideoList(): void {
    this.videoCallService.videoCallGetRecordingListByMeetingId(this.meetingId).subscribe((response: any) => {
      if (response.result) {
        response.result.forEach((item: any) => {
          this.playList.push({
            name: 'Recording ' + (this.playList.length + 1),
            url: item
          })
        })
        if (this.playList.length > 0) {
          this.payload.message = "Recording found";
          this.appInsights.trackEvent("Meeting Recording found",this.payload);
          this.timerRef = timer(0, 5000).subscribe(() => this.initRecording());
        } else {
          this.payload.message = "No Recording found";
          this.appInsights.trackEvent("Meeting Recording not found",this.payload);
          this.recordingAvailable = false;
        }
      }
    })
  }
  initRecording(): void {
    if (this.video && this.video.nativeElement) {
      this.videoThumbnailHandler(this.playList[0]);
      this.timerRef.unsubscribe();
    }
  }
  videoThumbnailHandler(listItem: any) {
    this.fullScreenVideo = true;
    this.videoPlayed = environment.baseApiUrl+'/api/VideoCall/recording/memorystream/'+listItem.url;
    this.video.nativeElement.src = environment.baseApiUrl+'/api/VideoCall/recording/memorystream/'+listItem.url;
    this.video.nativeElement.load();
    this.video.nativeElement.muted = true;
    this.video.nativeElement.play();
  }

}
