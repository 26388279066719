import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProviderService, ProviderServiceRequestModel } from '../../../api-client';
import { CommonConstants } from '../../../shared/common-constants';

@Component({
  selector: 'app-common-setting',
  templateUrl: './common-setting.component.html',
  styleUrls: ['./common-setting.component.scss']
})
export class CommonSettingComponent implements OnInit, OnChanges {
  @Input() title: any = '';
  @Input() serviceMasterId: any = '';

  // listOfProviders: any = [{ id: 'B45C30EE-0DC3-4D55-9A24-5C98765C0113', name: 'Twilio SMS', type: 'P' }, { id: 'AA3D7C65-9BD1-4A37-B299-B338210A9A68', name: 'Azue Communciation Sevice', type: 'P' }];

  listOfProviders: any;
  isActive: boolean = false;
  isPassiveActive: boolean = false;
  secondServiceProirty: string = '0%';


  requestModel: ProviderServiceRequestModel = {
    primaryServiceId: '',
    secondaryServiceId: '',
    primaryServiceType: '',
    secondaryServiceType: '',
    serviceStatusRetry: 1,
    serviceMasterId: this.serviceMasterId,
    primaryServicePriority: 1,
    secondaryServicePriority: 0
  };


  constructor(private providerService: ProviderService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    var username = localStorage.getItem(CommonConstants.LS_USERNAME);
    //if (username != CommonConstants.DEAFULT_USERNAME) {
    //  this.router.navigate(['/dashboard']);
    //}


    this.getPrivoderList();
  }

  ngOnChanges() {

    if (this.serviceMasterId != undefined)
      this.getPrivoderList();
  }


  public async getPrivoderList() {
    let providers: any = await this.providerService.providerGetProviderList(this.serviceMasterId).toPromise();
    this.listOfProviders = providers;
    this.requestModel.primaryServiceId = this.listOfProviders[0]?.id;
    this.requestModel.secondaryServiceId = this.listOfProviders[1]?.id;
    this.requestModel.primaryServicePriority = this.listOfProviders[0]?.priority;
    this.requestModel.secondaryServicePriority = this.listOfProviders[1]?.priority;
    this.secondServiceProirty = `${this.listOfProviders[1]?.priority}0%`;


    let PPList = this.listOfProviders.filter((x: any) => x.type == CommonConstants.PRIMARY_PROIRTY);
    let SList = this.listOfProviders.filter((x: any) => x.type == CommonConstants.SECONDARY_PROIRTY);

    if (PPList.length >= 2) {
      this.isActive = true;
      this.isPassiveActive = false;
      this.requestModel.primaryServiceType = CommonConstants.PRIMARY_PROIRTY;
      this.requestModel.secondaryServiceType = CommonConstants.PRIMARY_PROIRTY;

    } else if (PPList.length >= 1 && SList.length < 1) {
      this.isActive = true;
      this.isPassiveActive = false;
      this.requestModel.primaryServiceType = CommonConstants.PRIMARY_PROIRTY;

    }
    else {
      this.isPassiveActive = true;
      this.isActive = false;
      this.requestModel.primaryServiceType = CommonConstants.PRIMARY_PROIRTY;
      this.requestModel.secondaryServiceType = CommonConstants.SECONDARY_PROIRTY;
    }
  }

  onchangeActive(event: any) {

    if (event.target.checked == true) {
      this.requestModel.primaryServiceType = CommonConstants.PRIMARY_PROIRTY;
      this.requestModel.secondaryServiceType = CommonConstants.PRIMARY_PROIRTY;
    }

    this.isActive = true;
    this.isPassiveActive = false;
  }

  onchangeActivePassive(event: any) {
    if (event.target.checked == true) {
      this.requestModel.primaryServiceType = CommonConstants.PRIMARY_PROIRTY;
      this.requestModel.secondaryServiceType = CommonConstants.SECONDARY_PROIRTY;
    }
    this.isActive = false;
    this.isPassiveActive = true;
  }


  changeService1Weight(value: any) {
    if (this.listOfProviders.length >= 2) {
      let priorty: number = 10 - value;
      this.requestModel.secondaryServicePriority = priorty;
      this.secondServiceProirty = `${priorty}0%`;
    }

  }



  public async save() {
    this.requestModel.serviceMasterId = this.serviceMasterId;

    if (this.requestModel.primaryServiceId === this.requestModel.secondaryServiceId) {
      this.toastr.warning("Please select different value in both services dropdown");
      return;
    }


    let result: any = await this.providerService.providerUpdateProvider(this.requestModel).toPromise();

    if (result) {
      this.toastr.success("Response saved successfully");
    }
  }
}
