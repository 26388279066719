export * from './appMaster.service';
import { AppMasterService } from './appMaster.service';
export * from './appServiceTemplate.service';
import { AppServiceTemplateService } from './appServiceTemplate.service';
export * from './cache.service';
import { CacheService } from './cache.service';
export * from './chat.service';
import { ChatService } from './chat.service';
export * from './communication.service';
import { CommunicationService } from './communication.service';
export * from './history.service';
import { HistoryService } from './history.service';
export * from './mapping.service';
import { MappingService } from './mapping.service';
export * from './meetingStatistics.service';
import { MeetingStatisticsService } from './meetingStatistics.service';
export * from './provider.service';
import { ProviderService } from './provider.service';
export * from './providersConfig.service';
import { ProvidersConfigService } from './providersConfig.service';
export * from './serviceMaster.service';
import { ServiceMasterService } from './serviceMaster.service';
export * from './userMaster.service';
import { UserMasterService } from './userMaster.service';
export * from './videoCall.service';
import { VideoCallService } from './videoCall.service';
export * from './videoCallFeedback.service';
import { VideoCallFeedbackService } from './videoCallFeedback.service';
export const APIS = [AppMasterService, AppServiceTemplateService, CacheService, ChatService, CommunicationService, HistoryService, MappingService, MeetingStatisticsService, ProviderService, ProvidersConfigService, ServiceMasterService, UserMasterService, VideoCallService, VideoCallFeedbackService];
