<section class="section section--video">
    <main class="section__main">
        <div class="section__header">
            <div class="container">
                <img class="section__header-logo" src="../../../assets/img/somatus.png" alt="Somatus"/>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="section__title mb-4">Feedback</div>
                    <form [formGroup]="feedbackForm" (ngSubmit)="onFeedbackSubmit()" id="ngForm">
                        <div class="section__title fs-18 mb-2">CALL STATUS<span class="text-danger">*</span></div>
                        <select class="custom-select custom-select-lg mb-3" formControlName="meetingStatus">
                          <option value="" disabled selected>--Select call status--</option>
                          <option value="Completed">Completed</option>
                          <option value="Completed - With the help of CareTeam">Completed - With the help of CareTeam</option>
                          <option value="Failed - Patient No-Show">Failed - Patient no-show</option>
                          <option value="Failed - Patient Did Not receive Text Message">Failed - Patient did not receive text message</option>
                          <option value="Failed - Patient Did Not receive Email">Failed - Patient did not receive email</option>
                          <option value="Failed - Patient failed to start the call">Failed - Patient failed to start the call</option>
                          <option value="Failed - Patient Microphone Not working">Failed - Patient microphone not working</option>
                          <option value="Failed - Patient Camera Not working">Failed - Patient camera not working</option>
                          <option value="Failed - Patient has Poor Connection">Failed - Patient has poor connection</option>
                          <option value="Cancelled - Patient requested Reschedule">Cancelled - Patient requested reschedule</option>
                          <option value="Cancelled - requested Cancellation">Cancelled - Requested cancellation</option>
                        </select>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="section__title fs-18 mb-2">RATING<span class="text-danger">*</span></div>
                                <div class="mb-3">
                                    <!--Add class rating-1, rating-2 etc with 'star-rating' class for selected stars -->
                                    <div class="star-rating" [ngClass]="'star-rating-' + ratings">
                                        <div class="star-rating--1" (click)="selectedRating('1')"></div>
                                        <div class="star-rating--2" (click)="selectedRating('2')"></div>
                                        <div class="star-rating--3" (click)="selectedRating('3')"></div>
                                        <div class="star-rating--4" (click)="selectedRating('4')"></div>
                                        <div class="star-rating--5" (click)="selectedRating('5')"></div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6 p-0">
                                    <div class="section__title fs-18 mb-2">DEVICE<span class="text-danger">*</span></div>
                                    <div class="mb-4 mt-3 p-2">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadioios" 
                                                name="device" class="custom-control-input"
                                                formControlName="device" value="ios" />
                                            <label class="custom-control-label" for="customRadioios">ios</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadioAndroid" 
                                                name="device" class="custom-control-input"
                                                formControlName="device" value="Android" />
                                            <label class="custom-control-label" for="customRadioAndroid">Android</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input type="radio" id="customRadioDesktop" 
                                                name="device" class="custom-control-input"
                                                formControlName="device" value="Desktop" />
                                            <label class="custom-control-label" for="customRadioDesktop">Desktop</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="section__title fs-18 mb-2">COMMENT<span class="text-danger">*</span></div>
                        <textarea class="form-control" rows="3" formControlName="meetingfeedback"></textarea>
                    </form>
                    <div class="my-4">
                        <button type="submit" class="btn btn-lg btn-secondary px-sm-5"
                        [disabled]="!feedbackForm.valid"
                        form="ngForm">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</section>
